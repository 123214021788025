<template>
  <v-dialog
    v-model="dialog"
    persistent
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ formTitle }}</span>
      </v-card-title>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          depressed
          @click="closeDialog()"
        >
          Cancelar
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn
          color="primary"
          :loading="isSaving"
          depressed
          @click="AssingClients()"
        >
          Asignar Clientes
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
      <v-card-text>
        <v-container>
          <v-form
            ref="form"
          >
            <v-row>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="ClientAassigned"
                    :disabled="loadingClients"
                    :items="ClientData"
                    filled
                    chips
                    label="Seleccionar Cliente"
                    item-text="name"
                    item-value="id"
                    multiple
                    :loading="loadingClients"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="remove(data.item)"
                      >
                        {{ data.item.name + ' ' + data.item.surname }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item"></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="data.item.dni + ' - ' + data.item.name + ' ' + data.item.surname"
                          ></v-list-item-title>
                          <v-list-item-subtitle
                            v-if="(data.item.personIdAssigned !=null)"
                            v-html="data.item.personIdAssigned + '-' + data.item.nameAssigned
                              + ' ' + data.item.surnameAssigned"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from 'axios'

import {
  mdiEyeOutline,
} from '@mdi/js'

export default {
  data() {
    return {
      dialog: false,
      ClientAassigned: [],
      autoUpdate: true,
      employee: [{
        personId: 11,
        name: 'prueba',
        surname: 'prueba apellido',
      }],
      ClientData: [],

      icons: {

        mdiEyeOutline,

      },
      loadingButton: false,
      mensajeSnack: '',
      loadingClients: false,
      isSaving: false,
      employeePersonID: null,
    }
  },
  computed: {
    formTitle() {
      return 'Asignar Clientes'
    },

  },
  watch: {
  },
  methods: {
    openDialog(personId) {
      this.employeePersonID = personId
      this.getClientForAssigned()
      this.dialog = true
    },
    closeDialog() {
      this.dialog = false
    },
    remove(item) {
      const index = this.ClientAassigned.indexOf(item.id)
      if (index >= 0) this.ClientAassigned.splice(index, 1)
    },
    close() {
      this.$router.push({ name: 'clientsassigned', params: { id: this.$route.params.id } })
    },
    save(date) {
      this.$refs.menu.save(date)
    },
    submitForm() {
      this.dialog = false
      this.$refs.form.reset()
      this.$emit('Recargar')
    },

    async getClientForAssigned() {
      this.url = `${this.$store.getters.urlBase}/api/Client/AssignedToEmployee`

      const options = {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
        },
        params: { employeePersonID: this.employeePersonID },
        url: this.url,
      }
      this.loadingClients = true
      const respuesta = await axios(this.url, options)
      if (respuesta.status === 200) {
        this.ClientData = respuesta.data.clients
        this.loadingClients = false
      } else {
        this.loadingClients = false
      }
    },
    async AssingClients() {
      if (this.$refs.form.validate()) {
        this.url = `${this.$store.getters.urlBase}/api/Employee/assignClients`
        this.isSaving = true
        const params = {
          employeePersonID: this.$route.params.id,
          loggedPersonId: this.$store.getters.usuario.user_personid,
          clientsId: this.ClientAassigned,
        }

        const config = {
          headers: {
            Authorization: `Bearer ${this.$store.getters.usuario.token.token}`,
            'content-type': 'application/json',
          },
        }
        await axios
          .post(this.url, params, config)
          .then(
            // eslint-disable-next-line no-return-assign
            response => {
              this.respuesta = response
              this.isSaving = false
                this.$store.dispatch('showSnack', {
                  text: 'Se han asignado los clientes seleccionados',
                  color: 'success',
                  timeout: 2500,
                })
                this.ClientAassigned = []
                this.$emit('assigned')
                this.closeDialog()
                this.isSaving = false
            },
          )
          .catch(e => {
            if (e.response) {
              this.mensajeSnack = e.response.data
            } else {
              this.mensajeSnack = 'Error de conexion'
            }
            this.$store.dispatch('showSnack', {
              text: this.mensajeSnack,
              color: 'error',
              timeout: 2500,

            })
            this.isSaving = false
          })
      }
    },

  },
}
</script>
